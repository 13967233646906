const getRoute = (args: {
  endpont: string;
  id?: string | number | undefined;
  rest?: string | number | undefined;
}) => {
  const apiMap: { [key: string]: string } = {
    mail_list: "/api/mailing-list/email",
    get_estates: "/api/estates",
    post_estates: "/api/estates/filter",
    get_filter_prices: "/api/estates/stats/prices",
    get_filter_stats: "/api/estates/stats/filters",
    get_estate: `/api/estates/${args.id}`,
    get_countries: `/api/countries`,
    get_blockchains: `/api/chains`,
  };
  return apiMap[args.endpont];
};

export default getRoute;
